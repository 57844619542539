import { TPaymentTypes } from '@shared/models/payment';
/**
 * @description - получить тайтл полаты
 * @param method
 */
export const getPaymentMethodName = (method: TPaymentTypes): string => {
  if (method === 'online') return 'Онлайн оплата';
  if (method === 'on_receipt') return 'Оплата при получении';
  if (method === 'doliami') return 'Оплата долями';
};
