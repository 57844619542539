import React, { useEffect, useState } from 'react';
import { ScoreBalance } from '@entities/score';
import { AppDivider, AppFontIcon, AppInput, AppPopover, AppSelectCard, AppText } from '@ui-kit';
import './ChoosePaymentMethod.scss';
import { COLOR_GRAY_MAX, COLOR_GRAY_MINI, COLOR_ORANGE } from '@shared/constants/colors';
import { useAppSelector } from '@app/store/hooks';
import { IChoosePaymentMethodProps } from './ChoosePaymentMethod.types';
import { getNoun } from '@frontend-modules/frontend-utils';

/**
 * @description - компонент выбора способа оплаты
 * @param props
 */
const ChoosePaymentMethod = (props: IChoosePaymentMethodProps) => {
  const {
    activeDeliveryPoint,
    isPublic,
    onChange,
    selectedMethod,
    onChangeBonusesSum,
    cartList,
    deliveryData,
  } = props;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { allowedCod } = activeDeliveryPoint || {};

  const [isCoinsSelected, setCoinsSelected] = useState(false);
  const [localInputValue, setLocalInputValue] = useState(0);
  const [availableBonus, setAvailableBonus] = useState(0);

  const userBonusBalance = useAppSelector((state) => state.user?.data?.bonus || 0);
  const {
    minSumPayment: MIN_SUM_PAY,
    freeDelivery: FREE_DELIVERY_SUM,
    bonusRelation: BONUS_RELATION,
  } = useAppSelector((state) => state.meta.data);
  const cartListCost = cartList.reduce((total, product) => {
    const productCost = product.offer?.price * product.count;
    return total + productCost;
  }, 0);

  const isMoreThenFreeDeliverySum = cartListCost >= FREE_DELIVERY_SUM;
  const totalCostWithoutBonuses =
    (isMoreThenFreeDeliverySum ? 0 : deliveryData?.amount || 0) + (cartListCost || 0);
  /**
   * @description - если была выбрана оплата при получении,
   * а потом поменяли пункт выдачи, где так оплачивать нельзя -
   * ставим оплату онлайн
   */
  useEffect(() => {
    if (allowedCod === false && selectedMethod === 'on_receipt') {
      onChange('online');
    }
  }, [allowedCod, selectedMethod]);

  useEffect(() => {
    if (userBonusBalance > totalCostWithoutBonuses) {
      setAvailableBonus(totalCostWithoutBonuses > 0 ? totalCostWithoutBonuses - MIN_SUM_PAY : 0);
    } else {
      setAvailableBonus(userBonusBalance);
    }
  }, [userBonusBalance, totalCostWithoutBonuses, MIN_SUM_PAY]);

  /**
   *
   * @param value
   */
  const onInputChange = (value: string) => {
    setLocalInputValue(+value);
    // Проверка, является ли введенное значение числом и меньше или равным максимальному значению
    if ((!isNaN(+value) && parseFloat(value) <= availableBonus) || value === '') {
      onChangeBonusesSum(+value);
    } else {
      onChangeBonusesSum(availableBonus);
    }
  };

  /** */
  const onInputBlur = () => {
    if (localInputValue > availableBonus) {
      setLocalInputValue(availableBonus);
    }
  };

  return (
    <div className={'choose-payment-method'}>
      <AppSelectCard
        title={'Оплата онлайн'}
        type={'radio'}
        isActive={selectedMethod === 'online'}
        onClick={() => {
          onChange('online');
        }}
        topContent={<AppFontIcon icon={'card-double'} />}
      />
      {allowedCod !== false && (
        <AppSelectCard
          title={'При получении'}
          type={'radio'}
          isActive={selectedMethod === 'on_receipt'}
          onClick={() => {
            onChange('on_receipt');
          }}
          topContent={<AppFontIcon icon={'wallet'} />}
        />
      )}
      <AppSelectCard
        title={'Оплата долями'}
        type={'radio'}
        isActive={selectedMethod === 'doliami'}
        onClick={() => {
          onChange('doliami');
        }}
        topContent={<AppFontIcon icon={'shares'} />}
      />
      {!isPublic && (
        <>
          <AppDivider
            color={COLOR_GRAY_MINI}
            className={'choose-payment-method__divider'}
            direction={'vertical'}
          />
          <AppSelectCard
            title={'Оплата баллами'}
            isActive={isCoinsSelected}
            activeBorderColor={COLOR_ORANGE}
            topContent={<ScoreBalance score={availableBonus} icon={'coin-md'} />}
            onClick={() => {
              const isNewState = !isCoinsSelected;
              setCoinsSelected(!isCoinsSelected);
              onChangeBonusesSum(isNewState ? availableBonus : 0);
              setLocalInputValue(isNewState ? availableBonus : 0);
            }}
            rightElement={
              isCoinsSelected && (
                <div className={'choose-payment-method__coins-payment'}>
                  <AppText
                    text={'Кол-во для списания'}
                    type={'body'}
                    isNoWrap
                    color={COLOR_GRAY_MAX}
                  />
                  <div className={'choose-payment-method__coins-payment__input'}>
                    <AppInput
                      onBlur={onInputBlur}
                      mask={[{ mask: '0000000000' }]}
                      defaultValue={`${localInputValue}`}
                      onChange={onInputChange}
                    />
                    <AppPopover
                      trigger={'click'}
                      rootClassName={'choose-payment-method__coins-payment__input-hint-popup'}
                      content={
                        <div className={'choose-payment-method__coins-payment__input-hint'}>
                          <div
                            className={'choose-payment-method__coins-payment__input-hint__title'}
                          >
                            <ScoreBalance score={1} isWithPostWord />
                            <AppText fontType={'averta'} text={'='} color={COLOR_ORANGE} />
                            <ScoreBalance score={BONUS_RELATION} isFiat isWithPostWord />
                          </div>
                          <div
                            className={'choose-payment-method__coins-payment__input-hint__footer'}
                          >
                            <AppText
                              text={`Можно использовать для оплаты полной стоимости заказа за минусом ${MIN_SUM_PAY} ${getNoun(
                                MIN_SUM_PAY,
                                'рубля',
                                'рублей',
                                'рублей',
                              )}`}
                              color={COLOR_GRAY_MAX}
                              type={'signature'}
                            />
                          </div>
                        </div>
                      }
                    >
                      <AppFontIcon cursor={'pointer'} icon={'info'} color={COLOR_GRAY_MAX} />
                    </AppPopover>
                  </div>
                </div>
              )
            }
            type={'checkbox'}
          />
        </>
      )}
    </div>
  );
};

export default ChoosePaymentMethod;
